import "./style.css";

document.querySelector<HTMLDivElement>(".sousuo>div")!.onclick = () => {
    window.location.href = `/search.html?${document.querySelector<HTMLInputElement>(".sousuo>input")!.value}`;
};
document.querySelector<HTMLDivElement>(".header>div:nth-child(5)")!.onclick = () => {
    window.location.href = `/money.html`;
};

export{}
